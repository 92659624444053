.multi-select-container {
    background: white;
    border-radius: 0.5rem;
    height: 300px;
    box-sizing: border-box;
    border: 1px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .multi-select-header {
    padding: 0.5rem;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
  }
  
  .multi-select-scrollable label {
    display: block;
    margin-bottom: 8px;
  }
  
  .multi-select-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
  }
  
  .multi-select-scrollable {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  
  }
  
  .multi-select-input input {
    width: 100%;
    box-sizing: border-box;

    border: 1px solid #e5e7eb;
    padding: 0 10px 0 10px;
    border-radius:0px;
  }
  