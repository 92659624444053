span.highlight {
    color: red;
}

.result-item {
    direction: rtl;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e5e7eb;
    padding: .25rem;
}

.result-item > div {
  padding:.25rem;
}

.results-header {
  font-weight:bold;
}

.result-item .result-title {
    display: flex !important;
    align-items: flex-start !important;

}

.result-title h4 {
    margin: 0px;
}

.result-item .result-title {
    width: 20%;
    box-sizing: border-box;
}

.result-item .highlights {
    width: 70%;
    box-sizing: border-box;
}

.result-item .result-page {
    text-align: left;
    width: 10%;
    box-sizing: border-box;
}

.search-form-buttons {
  width:50%;
  margin:auto;
  flex-direction:row;
}

.results-container {
    background: white;
    border-radius: 0.5rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap:1rem;

}

.results-list {
    border: 1px solid #e5e7eb;
    border-radius: 5px;

}

.results-list .result-item:last-child {
    border-bottom: 0px !important;
}


@media (max-width: 468px) {
    .search-form {
      flex-direction: column;
    }
  
    .input-group {
      width: 100%;
    }
  
    .search-form-buttons {
      width: 100%;
    }
  
    .search-container {
      padding: .5rem
    }
  
    .result-item .result-title {
      width: 30%;
      box-sizing: border-box;
      padding:.25rem

    }
  
    .result-item .highlights {
      width: 55%;
      box-sizing: border-box;
      padding:.25rem

    }
  
    .result-item .result-page {
      text-align: left;
      width: 15%;
      box-sizing: border-box;
      padding:.25rem

    }

    .result-item {
        padding:.25rem
    }
  
  .author-shuhra {
    display: none;
  }
  }