.search-form-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  direction: rtl;
}

.search-form-wrapper {
  border-bottom:1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
}

.search-form-wrapper 

.search-form-container .input-group input[type="text"],
.nisba-group input[type="text"] {
  font-size: 1.5rem;
  padding: 5px;
  text-align: center;
}

.search-form-container .input-group:nth-child(1) {
  flex: 2.5;
}

.search-form-container .input-group:nth-child(2) {
  flex: 4;
}

.search-form-container .input-group:nth-child(3) {
  flex: 2.5;
}

.search-form-container .search-form-buttons {
  flex: 1;
}

.nisba-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.input-group,
.nisba-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nisba-group {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.rtl-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.search-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.form-buttons {
  flex: 1 1;

}

.search-form-buttons button {
  width: 100%;
}

.search-form-buttons, .form-buttons {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.search-layout {
  display: flex;
  gap: 1rem;
}

.add-nisba {
  margin-bottom:10px;
}

.search-sidebar {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-main {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.add-form-button:disabled:hover {
  background-color: rgba(239, 239, 239, 0.3);
  border: 1px solid #ccc;
  cursor: default;
}

.kunya-input {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.form-checkbox {
  text-align: left;
  direction: ltr;
  margin-top: 2px;
  display: flex;
  align-items: center; 
}

.search-input-section > div {
    padding: 1rem;
}

.search-input-section .search-form-wrapper:last-child {
  border-bottom:0px;
}

.search-input-section {
  margin-bottom:1rem;
}

.form-checkbox .tooltip-container {
padding-left:5px;
height:18px;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  height: 40px;
  padding-left: 48px; 
  padding-right: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

@media (max-width: 768px) {

  .search-container {
    padding: .5rem
  }

  .search-layout {
    flex-direction: column;
  }
  .search-sidebar {
    width:100%;
  }
  .search-main {
    width:100% !important;
  }

}

@media (max-width: 600px) {
  .search-form-container {
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .search-form-buttons {
    width: 100%;
  }
  
  .space-y-10 {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

  }

  .multi-select-container {
    flex: 1 1 48%;
    box-sizing: border-box;

  }

  .date-slider-container {
    width:80%;
    margin:auto;
  }


}

@media (max-width: 468px) {
  .search-form-container {
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .search-form-buttons {
    width: 100%;
  }

  .search-container {
    padding: .5rem
  }

  .search-layout {
    flex-direction: column;
  }
  .search-sidebar {
    width:100%;
  }
  .search-main {
    width:100% !important;
  }

}