body {
  margin: 0;
  font-family: 'Geist', -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: scroll;
}


* {
  box-sizing: border-box;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 0.9em;
  color: #d63384;
}


div {
  box-sizing: border-box;

}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

a {
  color: black;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

h2 {
  margin: 0 !important;
}



input[type="text"] {
  height: 2.5rem;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  height: 2rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button:hover {
  cursor: pointer;
  background-color: rgb(179, 179, 179);
  border: 1px solid black;
}

.center {
  text-align: center;
}

.text-content {
  max-width: 70rem;
  margin: auto;
  padding: 1rem;
  border-radius: 5px;
}

.text-content div {
  padding: 1rem;
}

.permutations-list {
  width: 50%;
  direction: rtl;
  padding-top:0px !important;

}

.permutations-list ul {
  list-style-type: none;
    margin:0px !important;
}

.red {
  color: Red;
}

.bold {
  font-weight: Bold;
}

.container {
  min-height: 70vh;
}

/* MultiSelect Styles */

.space-y-10 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* SearchPage Layout Styles */
.search-page {
  padding: 1.5rem;
  height: 100vh;
}

.search-container {
  max-width: 70rem;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap:1rem;
}

.filter-section {
  padding:1rem;
}

.filter-section,
.search-input-section {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.text-button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  height: 1rem;
}

.text-button:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: 0px;
}

.ltr {
  direction: ltr;
}

@media (max-width: 768px) {

  .permutations-list {
    width: 100%;
    direction: rtl;
  }
}

@media (max-width: 468px) {

  .search-sidebar {
    width: 100%;
  }

  .text-content ul {
    padding-left:1rem;
  }

  .search-main {
    width: 100%
  }

  .space-y-10 {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

  }

  .multi-select-container {
    flex: 1 1 48%;
    box-sizing: border-box;

  }



  .date-slider-container {
    flex: 0 0 100%;
  }

  .search-page {
    padding: .5rem;
  }
}