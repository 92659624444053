
.horizontal-slider {
    width: 100%;
    height: 24px;
    margin: 10px 0;
  }
  
  .thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 2px solid black;
    border-radius: 100%;
    display: block;
    height: 18px;
    width: 18px;
    top: -7px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.15s ease;
  }
  
  .thumb:hover {
    background-color: #ebf5ff;
    transform: scale(1.1);
  }
  
  .thumb:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
  }
  
  .thumb.active {
    background-color: #ebf5ff;
  }
  
  /* Track styles */
  .track {
    position: relative;
    background: #e5e7eb;
    height: 4px;
    border-radius: 999px;
  }
  
  /* Colored part of track */
  .track.track-0 {
    background: #e5e7eb;
  }
  
  /* Track between thumbs */
  .track.track-1 {
    background: black;
  }
  
  /* Track after second thumb */
  .track.track-2 {
    background: #e5e7eb;
  }
  
  /* Disabled states */
  .disabled .thumb {
    cursor: not-allowed;
    border-color: #9ca3af;
    background: #f3f4f6;
  }
  
  .disabled .track {
    background: #e5e7eb;
  }
  
  .disabled .track.track-0,
  .disabled .track.track-1 {
    background: #9ca3af;
  }
  .date-range-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
  }
  
  .date-input {
    width: 70px;
    padding: 4px;
    text-align: center;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .date-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
  
  .date-separator {
    font-size: 14px;
    color: #6b7280;
  }